<template>
  <div class="page">
    <!--搜索 -->
    <div class="searchBox">
      <div class="searchBox_left">
        <img src="./img/search.png" alt="" />
        <input
          v-on:input="search()"
          v-model="keyWord"
          type="text"
          placeholder="搜索"
          @keyup.enter="handerSearch()"
          @click="inputClick()"
        />
        <img src="./img/clear.png" alt="" @click.stop="clearKeyWord()" />
      </div>
      <div class="searchBox_right" @click="searchSomeThing()">
        {{ searchStr }}
      </div>
    </div>
    <!-- 搜索内容时展示 -->
    <div class="outSearchList" v-if="searchFlag == 2">
      <p class="emptyStr">{{ emptyStr }}</p>
      <div class="searchList">
        <div
          class="item"
          @click="toDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <img src="./img/search.png" alt="" />
          <div class="content">
            <span>{{ item.str_s }}</span>
            <span style="color: rgba(0, 0, 0, 0.85); font-weight: 600">{{
              keyWord
            }}</span>
            <span>{{ item.str_e }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 点击搜索按钮展示详情列表 -->
    <div class="showDetailListBox" v-if="searchFlag == 4">
      <div class="list">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          :finishedText="finishedText"
          ref="list"
        >
          <div
            class="item"
            v-for="(item, index) in listData2"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="box_top">
              <img src="./img/hot.png" alt="" />
              <div class="title">
                <span>{{ item.str_s }}</span>
                <span style="color: rgba(0, 0, 0, 0.85); font-weight: 600">{{
                  keyWord
                }}</span>
                <span>{{ item.str_e }}</span>
              </div>
            </div>
            <v-h5Mtml :content="item.topicContent"></v-h5Mtml>
            <div class="box_bottom">
              <img src="./img/watch.png" alt="" />
              <div class="watchNum">{{ item.readCount }}</div>
              <img src="./img/message.png" alt="" />
              <div class="messageNum">{{ item.praiseCount }}</div>
            </div>
          </div>
        </v-list>
      </div>
    </div>

    <!-- 点击搜索展示 -->
    <div class="clickSearchBox" v-if="searchFlag == 1">
      <!-- 搜索历史 -->
      <div class="searchHistory" v-if="historyList.length > 0">
        <div class="searchHistory_top">
          <div class="title">搜索历史</div>
          <img src="./img/delete.png" @click="isDialog = true" alt="" />
        </div>
        <div class="historyList">
          <div
            class="item"
            @click="keyWordChecked(item)"
            v-for="(item, index) in historyList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 热门搜索 -->
      <div class="hotSearch">
        <div class="hotSearch_top">
          <div class="title">热门搜索</div>
        </div>
        <div class="rankList">
          <div class="left">
            <div
              class="item"
              @click="keyWordChecked(item.searchWords)"
              v-for="(item, index) in rankList_l"
              :key="index"
            >
              <div class="rank">
                <span
                  v-if="index + 1 == 1 || index + 1 == 2 || index + 1 == 3"
                  style="color: #fff"
                  >{{ index + 1 }}</span
                >
                <span v-else>{{ index + 1 }}</span>
                <img v-if="index + 1 == 1" src="./img/no1.png" alt="" />
                <img v-if="index + 1 == 2" src="./img/no2.png" alt="" />
                <img v-if="index + 1 == 3" src="./img/no3.png" alt="" />
              </div>
              <div class="content">{{ item.searchWords }}</div>
            </div>
          </div>
          <div class="right">
            <div
              class="item"
              @click="keyWordChecked(item.searchWords)"
              v-for="(item, index) in rankList_r"
              :key="index"
            >
              <div class="rank">
                <span>{{ index + 6 }}</span>
              </div>
              <div class="content">{{ item.searchWords }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 未搜索展示 -->
    <div class="noSearchBox" v-if="searchFlag == 0">
      <div class="titleBox">
        <div class="titleBox_left">生活百事通</div>
        <div class="titleBox_right" @click="golifeExpertList()">
          <div class="more">更多</div>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="box_top">
            <img src="./img/hot.png" alt="" />
            <div class="title">{{ item.topicTitle }}</div>
          </div>
          <v-h5Mtml :content="item.topicContent"></v-h5Mtml>
          <div class="box_bottom">
            <img src="./img/watch.png" alt="" />
            <div class="watchNum">{{ item.readCount }}</div>
            <img src="./img/message.png" alt="" />
            <div class="messageNum">{{ item.praiseCount }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="titleBox">
        <div class="titleBox_left">在线咨询</div>
      </div>
      
      <div class="bottomBox">
        <div class="inBottomBox">
          <img @click="showPhoneDialog()" src="./img/onlineAsk.png" alt="" />
        </div>
      </div>
      -->
    </div>

    <!-- 在线咨询弹框 -->
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog_phone"
      @confirm="confirm_phone"
    >
      <p class="dialogTitle">提示</p>
      <p class="dialogText">确认拨打电话：{{ phoneNum }}吗？</p>
    </v-dialog>
    <!-- 删除搜索历史弹框 -->
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialogTitle">提示</p>
      <p class="dialogText">确认删除搜索历史吗？</p>
    </v-dialog>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import wx from "weixin-js-sdk";
import {
  getTopicListUrl,
  getLifeNewsTypeUrl,
  deleteSearchHistoryUrl,
  getSearchHistoryUrl,
  getHotSearchUrl,
  getLifeTopicListUrl,
} from "./api.js";
export default {
  name: "lifeExpert",
  data() {
    return {
      searchStr: "搜索",
      searchTitleFlag: 0,
      searchFlag: 0,
      isDialog: false,
      isDialog_phone: false,
      phoneNum: "13586566270",
      list: [],
      ids: [],

      emptyStr: "",
      keyWord: "",
      historyList: [],
      rankList_l: [],
      rankList_r: [],
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  watch: {
    searchFlag: {
      handler(newVal, oldVal) {
        if (newVal == 1) {
          this.getHistoryList();
          this.getRankList();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "邻里公约")) {
      this.getList();
      this.getHistoryList();
      this.getRankList();
    }
    gloabalCount("", 81, 1);
  },
  methods: {
    clearKeyWord() {
      if (this.searchFlag == 0) {
      } else {
        this.searchStr = "取消";
        this.keyWord = "";
        this.searchFlag = 1;
      }
    },
    inputClick() {
      if (this.keyWord.length == 0) {
        this.searchStr = "取消";
        this.searchFlag = 1;
      }
    },
    searchSomeThing() {
      if (this.searchStr == "搜索" && this.keyWord.length == 0) {
        this.$toast("请输入查询内容！");
        return;
      }
      if (this.searchStr == "取消") {
        this.searchFlag = 0;
        this.searchStr = "搜索";
      } else if (this.searchStr == "搜索") {
        this.searchFlag = 4;
        this.listData2 = [];
        this.requestData.curPage = 1;
        this.finished = false;
        this.onLoad();
      }
    },
    search() {
      if (this.keyWord.length == 0) {
        this.keyWord = "";
        this.searchStr = "取消";
        this.searchFlag = 1;
        return;
      } else if (this.keyWord.length > 0 && this.searchFlag != 4) {
        this.searchStr = "搜索";
        this.searchFlag = 2;
        this.listData = [];
        this.getTopicTitleList();
      } else if (this.keyWord.length > 0 && this.searchFlag == 4) {
        this.listData2 = [];
        this.requestData.curPage = 1;
        this.finished = false;
        this.onLoad();
      }
    },
    getTopicTitleList() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.keyWord && this.keyWord.length > 0) {
          let params = {
            topicTypes: localStorage.getItem("topicTypes"),
            topicTitle: this.keyWord,
            userId: this.userId,
            houseId: this.roomId || this.communityId || undefined,
          };
          this.$axios
            .get(`${getLifeTopicListUrl}`, { params: params })
            .then((res) => {
              if (res.code == 200) {
                if (res.data.length == 0) {
                  this.emptyStr = "暂无数据";
                } else {
                  this.emptyStr = "";
                }
                this.listData = res.data;
                this.listData.forEach((item, index) => {
                  item.str_e = item.topicTitle.slice(
                    item.topicTitle.indexOf(
                      this.keyWord[this.keyWord.length - 1]
                    ) + 1,
                    item.topicTitle.length
                  );
                  if (item.topicTitle.indexOf(this.keyWord[0]) != 0) {
                    item.str_s = item.topicTitle.slice(
                      0,
                      item.topicTitle.indexOf(this.keyWord[0])
                    );
                  }
                });
              }
            });
        }
      }, 500);
    },
    onLoad() {
      this.getSearchList();
    },
    getSearchList() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let params = {
          topicTypes: localStorage.getItem("topicTypes"),
          topicTitle: this.keyWord,
          userId: this.userId,
          sortField: "HOT",
          houseId: this.roomId || this.communityId || undefined,
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
        };
        this.$axios
          .get(
            `${getTopicListUrl}`,
            { params: params },
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            if (res.code === 200) {
              if (res.data.pages <= res.data.current) {
                this.finished = true;
              } else {
                this.$refs.list._data.loading = false;
              }
              this.finishedText = "没有更多了";
              if (res.data.records.length === 0) {
                this.finishedText = "";
                return;
              }
              res.data.records.forEach((item) => {
                this.listData2.push(item);
              });
              this.listData2.forEach((item, index) => {
                item.str_e = item.topicTitle.slice(
                  item.topicTitle.indexOf(
                    this.keyWord[this.keyWord.length - 1]
                  ) + 1,
                  item.topicTitle.length
                );
                if (item.topicTitle.indexOf(this.keyWord[0]) != 0) {
                  item.str_s = item.topicTitle.slice(
                    0,
                    item.topicTitle.indexOf(this.keyWord[0])
                  );
                }
              });
            }
          });
      }, 500);
    },
    //关键字点击展示列表
    keyWordChecked(str) {
      this.searchStr = "搜索";
      this.keyWord = str;
      this.searchFlag = 4;
      this.listData2 = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    // 取消
    closeSearch() {
      if (this.searchStr == "搜索") {
      } else if (this.searchStr == "取消") {
      }
      this.searchFlag = 0;
      this.keyWord = "";
      this.getHistoryList();
      this.getRankList();
    },
    // 删除搜索历史确认
    confirm() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .post(`${deleteSearchHistoryUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.isDialog = false;
            this.getHistoryList();
          }
        });
    },
    // 获取热门搜索
    getRankList() {
      let params = {
        searchSouce: "1",
      };
      this.$axios.get(`${getHotSearchUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.rankList_l = res.data.slice(0, 5);
          this.rankList_r = res.data.slice(5, res.data.length + 1);
        }
      });
    },
    // 获取搜索历史
    getHistoryList() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getSearchHistoryUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.historyList = res.data;
          }
        });
    },
    // 跳转详情
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/lifeExpertDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    // 获取生活百事通前三热度
    getList() {
      this.$axios.get(`${getLifeNewsTypeUrl}`).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            this.ids.push(item.code);
          });
          localStorage.setItem("topicTypes", this.ids);
          let params = {
            topicTypes: this.ids.join(","),
            userId: this.userId,
            sortField: "HOT",
          };
          this.$axios
            .get(`${getTopicListUrl}`, { params: params })
            .then((res) => {
              if (res.code == 200) {
                this.list = res.data.records.slice(0, 3);
              }
            });
        }
      });
    },
    golifeExpertList() {
      this.$router.push({
        name: "lifeExpertList",
      });
    },
    golifeExpertSearch() {
      this.$router.push({
        name: "lifeExpertSearch",
      });
    },
    // 在线咨询弹框
    showPhoneDialog() {
      this.isDialog_phone = true;
    },
    // 在线咨询确认按钮
    confirm_phone() {
      window.location.href = `tel://${this.phoneNum}`;
      // this.isDialog_phone = false;
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafafa;
  .showDetailListBox {
    height: calc(100vh - 134px);
    background-color: #fafafa;
    .list {
      margin-top: 28px;
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
      .item {
        width: 100%;
        height: 256px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
        border-radius: 16px;
        margin-bottom: 30px;
        padding: 40px 22px 24px 40px;
        box-sizing: border-box;
        .box_bottom {
          width: 100%;
          display: flex;
          align-items: center;
          img {
            width: 32px;
            display: block;
            margin-right: 8px;
          }
          .watchNum {
            margin-right: 32px;
          }
          div {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.25);
          }
        }
        .box_center {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          height: 65px;
          margin-bottom: 34px;
          overflow: hidden;
          text-overflow: ellipsis; //显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 2; //块元素显示的文本行数
          -webkit-box-orient: vertical;
          /deep/ p,
          /deep/ h2,
          /deep/ h1,
          /deep/ strong,
          /deep/ span {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5) !important;
            line-height: 34px;
          }
        }
        .box_top {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          img {
            width: 28px;
            height: 36px;
            display: block;
            margin-right: 10px;
          }
          .title {
            font-size: 32px;
            // font-weight: 600;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      // .item:nth-last-child(1) {
      //   margin-bottom: 60px;
      // }
    }
  }
  .outSearchList {
    height: calc(100vh - 106px);
    background-color: #fff;
    .emptyStr {
      text-align: center;
      color: #ccc;
      font-size: 30px;
    }
    .searchList {
      width: 100%;
      padding: 0 50px;
      box-sizing: border-box;
      .item {
        width: 100%;
        height: 96px;
        border-bottom: 2px solid rgba(157, 157, 157, 0.1);
        display: flex;
        align-items: center;
        img {
          width: 22px;
          height: 26px;
        }
        .content {
          margin-left: 8px;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .clickSearchBox {
    position: fixed;
    top: 106px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    .hotSearch {
      width: 100%;
      padding-left: 54px;
      padding-right: 46px;
      box-sizing: border-box;
      .rankList {
        display: flex;
        .left,
        .right {
          flex: 1;
          .item {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 34px;
            .rank {
              width: 36px;
              height: 36px;
              background: #f7f8fa;
              border-radius: 8px;
              line-height: 36px;
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              position: relative;
              color: rgba(0, 0, 0, 0.25);
              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                color: rgba(0, 0, 0, 0.25);
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 36px;
                height: 36px;
              }
            }
            .content {
              padding: 0 18px;
              box-sizing: border-box;
              flex: 1;
              font-size: 24px;
              color: rgba(0, 0, 0, 0.85);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .hotSearch_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 34px;
        .title {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    .searchHistory {
      width: 100%;
      padding-left: 54px;
      padding-right: 46px;
      box-sizing: border-box;
      .historyList {
        display: flex;
        flex-wrap: wrap;
        .item {
          background: #f7f8fa;
          border-radius: 8px;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
          padding: 10px 30px;
          box-sizing: border-box;
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
      .searchHistory_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 34px;
        .title {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.25);
        }
        img {
          width: 22px;
          height: 24.93px;
        }
      }
    }
  }
  .noSearchBox {
    .bottomBox {
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
      height: 170px;
      margin-top: 26px;
      .inBottomBox {
        width: 100%;
        height: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
        border-radius: 16px;
        box-sizing: border-box;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .list {
      margin-top: 28px;
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
      .item {
        width: 100%;
        height: 256px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
        border-radius: 16px;
        margin-bottom: 30px;
        padding: 40px 22px 24px 40px;
        box-sizing: border-box;
        .box_bottom {
          width: 100%;
          display: flex;
          align-items: center;
          img {
            width: 32px;
            display: block;
            margin-right: 8px;
          }
          .watchNum {
            margin-right: 32px;
          }
          div {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.25);
          }
        }
        .box_center {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          height: 65px;
          margin-bottom: 34px;
          overflow: hidden;
          text-overflow: ellipsis; //显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 2; //块元素显示的文本行数
          -webkit-box-orient: vertical;
          /deep/ p,
          /deep/ h2,
          /deep/ h1,
          /deep/ strong,
          /deep/ span {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5) !important;
            line-height: 34px;
          }
        }
        .box_top {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          img {
            width: 28px;
            height: 36px;
            display: block;
            margin-right: 10px;
          }
          .title {
            font-size: 32px;
            font-weight: 600;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .item:nth-last-child(1) {
        margin-bottom: 60px;
      }
    }
    .titleBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      box-sizing: border-box;
      .titleBox_left {
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .titleBox_right {
        display: flex;
        font-size: 28px;
        color: #fe9514;
        align-items: center;
        img {
          display: block;
          width: 12px;
          height: 20px;
          margin-left: 8px;
        }
      }
    }
    .titleBox:nth-child(1) {
      margin-top: 28px;
    }
  }
  .dialogTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    padding: 50px 0 26px 0;
  }
  .dialogText {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 40px;
  }
  .searchBox {
    // margin-bottom: 28px;
    width: 100%;
    height: 106px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #fff;
    .searchBox_left {
      flex: 1;
      height: 66px;
      background: #f7f8fa;
      border-radius: 33px;
      display: flex;
      align-items: center;
      padding: 0 26px;
      box-sizing: border-box;
      img {
        width: 22px;
        height: 24px;
        margin-right: 14px;
      }
      input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .searchBox_right {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
      margin-left: 24px;
    }
  }
}
</style>
